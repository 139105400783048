import React, { useState, useEffect } from 'react'
import './index.scss'
import nftgif from '../../../../assets/images/mint-gif.gif'

function MintSection() {
    const [mintAmount, setMintAmount] = useState(1)
    const [limit, setLimit] = useState(5)

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
        if (limit === null) return
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > limit) {
            newMintAmount = limit;
        }
        if (limit === 0) {
            newMintAmount = 1
        }
        setMintAmount(newMintAmount);
    };

    return (
        <div className='mint-section'>
            <div className='mint-section-inner'>
                <div className='mint-section-container'>
                    <div className='mint-section-nft-video'>
                        <img src={nftgif} />
                    </div>
                    <div className='mint-section-mint-wrapper'>
                        <div className='mint-section-mint-amount-wrapper '>
                            <button onClick={() => { decrementMintAmount() }} className='mint-section-mint-amount-btn ffam-lato fs-ms tc-white fw-bold'>-</button>
                            <span className='fc-black ffam-nunito fw-extra-bold fs-m'>{mintAmount}</span>
                            <button onClick={() => { incrementMintAmount() }} className='mint-section-mint-amount-btn  ffam-lato fs-ms tc-white fw-bold'>+</button>
                        </div>

                        <button className='mint-section-mint-btn'>
                            <span className='fc-white ffam-nunito fw-extra-bold fs-s'>
                                Connect Wallet
                            </span>
                        </button>

                    </div>
                </div>
                <p className='mint-paragraph ffam-poppins fs-xs fw-med fc-black'>
                    <p className='mint-title ffam-serifiyan fs-l fw-semi-bold'>
                        Our Champions
                    </p>
                    <br/>
                    
                    Our collectibles are of HealthCare Champions (HCC) and Essential Workers who made their mark in history. HCC NFTs will contribute to individuality, and personality. These tributes will change the status quo of the stagnant NFT environment. We will accomplish this by merging web 3 technologies with real world applications.
                </p>
            </div>
        </div>
    )
}

export default MintSection