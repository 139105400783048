
import './Footer.scss';
import footerLogo from '../../assets/images/logo/hcc-logo.png'
import linktreeLogo from '../../assets/images/social/linktree.svg'
import instaLogo from '../../assets/images/social/insta.png'
import openseaLogo from '../../assets/images/social/opensea.png'

function Footer() {
    const openLink =(link)=>{
        window.open(link)
    }
    return (
        <div className="footer">
            <div className="footer-inner">

                <div className='footer-middle'>
                    <img className='' src={footerLogo}></img>
                    <div className='footer-middle-bottom'>
                        <button onClick={()=>{openLink('https://linktr.ee/HealthCareChampions?utm_source=linktree_profile_share&ltsid=ac0156ea-fdd1-4837-923f-5cd25a05649e')}} className='footer-social-img'>
                            <img id='linktree-logo' src={linktreeLogo} />
                        </button>
                        <button onClick={()=>{openLink('https://www.instagram.com/healthcarechampions/')}} className='footer-social-img'>
                            <img src={instaLogo}/>
                        </button>
                        <button onClick={()=>{openLink('https://opensea.io/HealthCareChampions')}} className='footer-social-img'>
                            <img src={openseaLogo}/>
                        </button>
                    </div>
                </div>
                <p className='footer-copy ffam-poppins fs-xs fw-med'>@ 2023: Health Care Champions</p>

            </div>
        </div>
    );
}

export default Footer;
