
import './Welcome.scss';
import { useEffect } from 'react';
import Footer from '../../../../components/footer/Footer';
import smoothscroll from "smoothscroll-polyfill";
import CardVideo from '../../../../assets/videos/card-video.mp4'
window.__forceSmoothScrollPolyfill__ = true;

function Home() {
    useEffect(() => {
        smoothscroll.polyfill();
    }, []);


    return (
        <div className="welcome-section">
            <div>
                <div className="welcome-section-inner">
                    <div className='welcome-statement ffam-poppins fs-welcome-p-font fs-xs fw-med fc-white'>

                        <div className='welcome-title ffam-serifiyan fs-welcome-title fw-semi-bold'>Welcome To The Official HealthCare Champions Website!</div>

                        <p className='welcome-paragraph'>
                            <br />
                            A call to action is requested from all our health care professionals and essential workers.
                            <br />
                            <br />
                            Our brand is evolving and expanding!
                            <br />
                            The brand dedicates to preserve the HealthCare Champions NFT community. Our focus is to:
                        </p>



                        <div className='indented-section'>
                            <div>
                                •
                            </div>
                            <div>
                                Merge real world healthcare with web 3
                            </div>
                        </div>
                        <div className='indented-section'>
                            <div>
                                •
                            </div>
                            <div>
                                Create personalized NFT for our community
                            </div>
                        </div>
                        <div className='indented-section'>
                            <div>
                                •
                            </div>
                            <div>
                                Create an economy for the professionals and essential workers
                            </div>
                        </div>
                        <div className='indented-section'>
                            <div>
                                •
                            </div>
                            <div>
                                Document an era of uncertainty and survival by digitizing Art on the block chain
                            </div>
                        </div>

                        <br />


                        <p className='welcome-paragraph'>

                            Help us stop the spread of these monstrous diseases by eradicating all enemies that we come in contact with.
                            <br />
                            <br />
                            Please stay tune for more critical information, supply closet, previews, social media updates, and exclusive NFT drops.
                            <br />
                            <br />
                            <center>
                            Thank you for stopping by!
                            </center>
                          
                          
                        </p>

                    </div>
                    <div className='card-video-wrapper'>
                        <br />
                        <br />
                        <video className='card-video' loop autoPlay playsInline muted>
                            <source src={CardVideo}></source>
                        </video>
                    </div>
                </div>
               
            </div>

            <Footer/>
        </div>
    );
}

export default Home;
