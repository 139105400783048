import React, { useEffect, useState } from "react";
import Timer from "../../misc/timer";
import "./Carousel.scss";

import img1 from "../../assets/images/carousel/1.webp";
import img2 from "../../assets/images/carousel/2.webp";
import img3 from "../../assets/images/carousel/3.webp";
import img4 from "../../assets/images/carousel/4.webp";
import img5 from "../../assets/images/carousel/1.webp";
import img6 from "../../assets/images/carousel/2.webp";
import img7 from "../../assets/images/carousel/3.webp";
import img8 from "../../assets/images/carousel/4.webp";
import img9 from "../../assets/images/carousel/1.webp";
import img10 from "../../assets/images/carousel/2.webp";
import img11 from "../../assets/images/carousel/3.webp";
import logo from '../../assets/images/logo/hcc-logo.png'

let carousel1Imgs = [
  {
    src: img1,
  },
  {
    src: img2,

  },
  {
    src: img3,

  },
  {
    src: img4,

  },
  {
    src: img5,

  },
  {
    src: img6,

  },
  {
    src: img7,

  },
  {
    src: img8,

  },
  {
    src: img9,

  },
  {
    src: img10,

  },
  {
    src: img11,

  },

  //add here

  //////////
  {
    src: img1,
  },
  {
    src: img2,

  },
  {
    src: img3,

  },
  {
    src: img4,

  },
  {
    src: img5,

  },
  {
    src: img6,

  },
  {
    src: img7,

  },
  {
    src: img8,

  },
  {
    src: img9,

  },
  {
    src: img10,

  },
  {
    src: img11,

  },

];



function Carousel() {

  const [timer,setTimer]=useState({})
  useEffect(() => {


    let carousel1 = document.getElementById('carousel-track-1');
    let carousel2 = document.getElementById('carousel-track-2');
    let carousel3 = document.getElementById('carousel-track-3');


    let appWidth = window.innerWidth;

    let pos1 = 0;
    let pos2 = 0 ;
    let pos3 =300;
    console.log(pos2)
    window.addEventListener('resize', () => {
      pos1 =0;
      pos2 =0;
      pos3 =0;
      appWidth = window.innerWidth;
    })
    
    let timer = new Timer(() => {
      pos1 += 0.3;
      pos2 += 0.3;
      pos3 += 0.3;

      carousel1.style.left = `-${pos1}px`
      carousel2.style.right = `-${pos2}px`
      carousel3.style.left = `-${pos3}px`

      if (Math.floor(pos1) === (document.getElementById('carousel-track-1').offsetWidth - appWidth)) {
        carousel1.style.left = `-${(document.getElementById('carousel-track-1').offsetWidth / 2) - appWidth}px`;
        pos1 = (document.getElementById('carousel-track-1').offsetWidth / 2) - appWidth
      }

      if (Math.floor(pos2) === (document.getElementById('carousel-track-2').offsetWidth - appWidth)) {
        carousel2.style.right = `-${(document.getElementById('carousel-track-2').offsetWidth / 2) - appWidth}px`;
        pos2 = (document.getElementById('carousel-track-2').offsetWidth / 2) - appWidth
      }

      if (Math.floor(pos3) === (document.getElementById('carousel-track-3').offsetWidth - appWidth)) {
        carousel2.style.right = `-${(document.getElementById('carousel-track-3').offsetWidth / 2) - appWidth}px`;
        pos3 = (document.getElementById('carousel-track-3').offsetWidth / 2) - appWidth
      }

    }, [10]);

    setTimer(timer)

    return(()=>{
      timer.stop()
    })
  }, []);



  return (
    <div className="carousel">
      <div className="carousel-logo-wrapper">
        <img src={logo} className="carousel-logo"/>
      </div>
      <div className="carousel-inner ">
        <div className="carousel-wrapper">
          <div id='carousel-track-1' className="carousel-track">
            {
              carousel1Imgs.map(({ src }) => {
                return (
                  <img key={`${Math.random()}`} className="carousel-img" src={src} />
                )
              })
            }
          </div>
          <div id='carousel-track-2' className="carousel-track">
            {
              carousel1Imgs.map(({ src }) => {
                return (
                  <img key={`${Math.random()}`} className="carousel-img" src={src} />
                )
              })
            }
          </div>
          <div id='carousel-track-3' className="carousel-track">
            {
              carousel1Imgs.reverse().map(({ src }) => {
                return (
                  <img key={`${Math.random()}`} className="carousel-img" src={src} />
                )
              })
            }
          </div>
        </div>

      </div>
    </div>
  );
}

export default Carousel;
