import React from 'react'
import './Roadmap.scss'
import roadmapBackground from '../../assets/images/roadmap/bg-9.png'
import roadmapBackgroundMobile from '../../assets/images/roadmap/bg-mobile.png'
function Roadmap() {
  return (
    <div className='roadmap-page'>
      <div className='roadmap-page-inner'>
        {/* <div className='roadmap-page-text ffam-serifiyan'>
          <span className='roadmap-title  fs-m fw-semi-bold'>HealthCare Champions Assessment</span>
          
          <div className='roadmap-text-divider'/>

          ✅ 8 Exclusive Character Development
          <br/>
          April-5-2023
          <div className='roadmap-text-divider'/>
          ✅ 8 ID Badges
          <br/>
          April-5-2023
          <div className='roadmap-text-divider'/>
          <span className='tick-box'> ☐</span> 5,000 NFT HealthCare Champions in Development
          On Its Way
          <div className='roadmap-text-divider'/>
          ☐Website Design
          Happening Soon
          <div className='roadmap-text-divider'/>
          ☐Launch HCC Website
          To be Announced
          <div className='roadmap-text-divider'/>
          ☐NFT Essential Worker Characters in Development
          In the Works
          <div className='roadmap-text-divider'/>
          ☐Building a Strong Telegram Community
          #💙🩺 Taking the Pulse
          <div className='roadmap-text-divider'/>
          ☐Whitelisting
          ETA Pending
          <div className='roadmap-text-divider'/>
          ☐Private NFT PreSale
          Waiting Patiently
          <div className='roadmap-text-divider'/>
          ☐Launch NFT Public Sale
          Hang On
          <div className='roadmap-text-divider'/>
          ☐Collaborate with Game Developers
          Under Construction
          <div className='roadmap-text-divider'/>
          ☐ERC 20 Tokens Antidotes
          On Hold
          <div className='roadmap-text-divider'/>
          ☐Virus Release
          The Spread Begins
        </div> */}
        {/* <div className='roadmap-page-text ffam-serifiyan'>
          <div className='roadmap-title  fs-m fw-semi-bold'>HealthCare Champions Assessment</div>
          
          <div className='roadmap-indented-section'>
            <div>
              ✅
            </div>
            <div>
              8 Exclusive Character Development
              <br/>
              April-5-2023
            </div>
          </div>

          <div className='roadmap-indented-section'>
            <div>
              ✅
            </div>
            <div>
              8 ID Badges
              <br/>
              April-5-2023
            </div>
          </div>
          
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              5,000 NFT HealthCare Champions in Development
              <br/>
              On Its Way
            </div>
          </div>
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              Website Design
              <br/>
              Happening Soon
            </div>
          </div>
     
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              Launch HCC Website
              <br/>
              To be Announced
            </div>
          </div>
    
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              NFT Essential Worker Characters in Development
              <br/>
              In the Works
            </div>
          </div>
       
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              Building a Strong Telegram Community
              <br/>
              #💙🩺 Taking the Pulse
            </div>
          </div>
    
           
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              Whitelisting
              <br/>
              ETA Pending
            </div>
          </div>

          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              Private NFT PreSale
              <br/>
              Waiting Patiently
            </div>
          </div>
         
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              Launch NFT Public Sale
              <br/>
              Hang On
            </div>
          </div>
          
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              Collaborate with Game Developers
              <br/>
              Under Construction
            </div>
          </div>
          
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              ERC 20 Tokens Antidotes
              <br/>
              On Hold
            </div>
          </div>
          
          <div className='roadmap-indented-section'>
            <span className='tick-box'> ☐</span> 
            <div>
              Virus Release
              <br/>
              The Spread Begins
            </div>
          </div>
          
        </div> */}
        <img className='roadmap-page-bg-img' src={roadmapBackground} />
        <img className='roadmap-page-bg-img-mobile' src={roadmapBackgroundMobile} />
      </div>
    </div>
  )
}

export default Roadmap