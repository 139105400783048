
import './Mint.scss';
import { useEffect } from 'react';
import Footer from '../../components/footer/Footer';
import smoothscroll from "smoothscroll-polyfill";
import CarouselSection from '../../components/carousel/Carousel'
import NftIntro from './sections/nft-intro/NftIntro';
import MintSection from './sections/mint'
window.__forceSmoothScrollPolyfill__ = true;

function Home() {
    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    useEffect(() => {
        const carousel = document.getElementsByClassName('carousel')[0].offsetTop
        const app = window
        const header = document.getElementsByClassName('header')[0]

        app.addEventListener('scroll', () => {
            let currentTop = document.getElementsByClassName('carousel')[0]?.getBoundingClientRect().top
            if (currentTop < carousel ) {
                header.style.boxShadow = ' 0px 0px 6px rgb(190, 190, 190)'
            }
            else{
                header.style.boxShadow = ' 0px 0px 0px rgb(190, 190, 190)'
            }
        });
    })

    // const animate=(element,percent)=>{
    //     const app = window
    //     element=document.getElementsByClassName(element)[0]
    //     let initialTop = element?.offsetTop

    //     app.addEventListener('scroll', () => {
    //         // if (window.innerWidth <= 1155) return;
            
    //         let currentTop = element?.getBoundingClientRect().top
    //         // console.log(currentTop,initialTop)
    
    //         if(currentTop < (initialTop*percent)){
    //             // element.style.opacity=1
    //             // element.style.top='0px'
    //             element.classList.add('slide-to-top')
    //         }
    //     });
    // }



    // useEffect(() => {

    //     //the higher the percent the shorter it takes to run the animation
    //     animate('about-section',0.755)
    //     animate('health-section',0.335)
        
    //     animate('utility-section-container',0.335)
    //     animate('utility-section-founders-message',0.205)

        
    //     animate('roadmap-contents-wrapper',7.5)
    //     animate('roadmap-section-1',2)
    //     animate('roadmap-section-2',0.5)
    //     animate('roadmap-section-3',0.335)
    //     animate('roadmap-section-4',0.235)

    //     animate('roadmap-section-5',0.135)


    //     animate('elders-carousel',0.1)
    //     animate('tech-council',0.1)

    //     animate('faq-section-inner',0.1)

    // },[])


    return (
        <div className="mint-page">
            <CarouselSection />
            <NftIntro/>
            <MintSection/>
            <Footer type='light' />
        </div>
    );
}

export default Home;
