import React from 'react'
import './NftIntro.scss'
import img1 from "../../../../assets/images/carousel/1.webp";
import img2 from "../../../../assets/images/carousel/2.webp";
import img3 from "../../../../assets/images/carousel/3.webp";
import img4 from "../../../../assets/images/carousel/4.webp";
function NftIntro() {
    return (
        <div className='nft-intro'>
            <div className='nft-intro-container '>
                <div className='nft-intro-inner'>
                    <div className='nft-intro-left'>
                        <p className='nft-intro-title ffam-serifiyan fs-l fw-semi-bold'>
                            Healthcare Champions
                        </p>

                        <p className='nft-intro-paragraph ffam-poppins fs-xs fw-med fc-l-gray'>
                            The Healthcare Champions 5,000 NFTs are designed to resemble the workers that kept humanity alive. HCC NFTs were inspired by the ingenuity and resourcefulness of humankind.
                            <br />
                            <br />
                            The NFTs have multiple purposes. They can be used to showcase social media profiles. The traits display special talents and abilities to the character. That could later unlock rare perks and contribute to the vision of a robust community.
                            <br />
                            <br />
                            The NFTs are structured into 4 levels. The structure level is:
                        </p>

                        <div className='nft-intro-level-wrapper ffam-poppins'>
                            <div className='nft-intro-level '>
                                <span className='fs-s fw-semi-bold'>Level 1</span>
                                <span className='fs-xs'>1% Club</span>
                            </div>
                            <div className='nft-intro-level '>
                                <span className='fs-s fw-semi-bold'>Level 2</span>
                                <span className='fs-xs'>Preventive Measures</span>
                            </div>
                            <div className='nft-intro-level '>
                                <span className='fs-s fw-semi-bold'>Level 3</span>
                                <span className='fs-xs'>Universal Precautions</span>
                            </div>
                            <div className='nft-intro-level '>
                                <span className='fs-s fw-semi-bold'>Level 4</span>
                                <span className='fs-xs'>High Risk</span>
                            </div>
                        </div>
                    </div>

                    <div className='nft-intro-right ffam-poppins'>
                        <div className='nft-intro-level-img-wrapper'>
                            <img src={img1}/>
                            <div className='fw-semi-bold fc-white'>1% Club</div>
                        </div>
                        <div className='nft-intro-level-img-wrapper'>
                            <img src={img2}/>
                            <div className='fw-semi-bold fc-white'>Preventive Measures</div>
                        </div>
                        <div className='nft-intro-level-img-wrapper'>
                            <img src={img4}/>
                            <div className='fw-semi-bold fc-white'>Universal Precautions</div>
                        </div>
                        <div className='nft-intro-level-img-wrapper'>
                            <img src={img3}/>
                            <div className='fw-semi-bold fc-white'>High Risk</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NftIntro