import React, { useState, useRef } from 'react'
import './LandingPage.scss'
// import Footer from '../../components/footer/Footer'
import welcomeMp4 from '../../assets/videos/landing-video.mp4'
import welcomeWebm from '../../assets/videos/landing-video.webm'
import WelcomeSection from './sections/welcome/Welcome'

import { useNavigate } from 'react-router-dom'



function WelcomePage() {
    const [playing, setPlaying] = useState(false)
    const video = useRef()
    const navigateTo = useNavigate()


    return (
        <div className='landing-page'>
            <div className='landing-page-top-section'>
                <div className='landing-page-img-wrapper'>

                    <video ref={video} muted playsInline autoPlay className='landing-img'>
                        <source src={welcomeWebm}></source>
                        <source src={welcomeMp4}></source>
                    </video>


                    <div className='landing-page-enter-wrapper ffam-poppins  fc-white fw-semi-bold'>

                        <p className=''>
                            WELCOME TO
                            <br />
                            HEALTHCARE CHAMPIONS
                        </p>
                        <div className='landing-social-wrapper'>
                            <div className='landing-social-wrapper-border' />


                            <div className='landing-social-wrapper-border' />
                        </div>
                        <button onClick={() => { navigateTo('/welcome') }} className='landing-btn fs-s ffam-lato fw-bold'>ENTER</button>
                    </div>
                </div>
                <div className='landing-page-inner'>


                </div>

            </div>
            <WelcomeSection/>
        </div>
    )
}

export default WelcomePage