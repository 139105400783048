
import './Header.scss';

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';


import logo from '../../assets/images/logo/hand-logo.jpg'

function Header({useHeader}) {
    const navigate = useNavigate()
    const [welcomeBtnVisible, setWelcomeBtnVisible] = useState(false)
    const appLocation = useLocation()
    useEffect(() => {
      if (appLocation.pathname !== '/') {
        setWelcomeBtnVisible(false)
      }
      else {
        setWelcomeBtnVisible(true)
      }
    }, [appLocation])

    const navigateTo = async (location) => {
        const header = document.getElementsByClassName('header')[0]
        var height = header.offsetHeight
        var container = window
        var offsetTop;
       

        switch (location) {
            case "/welcome":
                offsetTop = document.getElementsByClassName("welcome-section")[0].offsetTop - height ;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            
            default:
                break;
        }

    }



    return (
        <div className="header" style={{display:useHeader?'block':'none '}}>

            <div className="header-inner">

                <div className='header-left'>
                    <div onClick={() => { navigate('/') }} className='header-desktop-logo-wrapper'>
                        <img src={logo} />
                    </div>
                    <span className='fs-header fc-black ffam-nunito fw-extra-bold'>
                        HealthCare Champions
                    </span>
                </div>
               
                <div className='header-middle'>
                    {
                        welcomeBtnVisible&&
                        <button onClick={() => { navigateTo('/welcome') }} id='header-desktop-dropdown-open-btn' className='fs-header fc-black ffam-nunito fw-extra-bold'>
                            WELCOME
                        </button>
                        
                    }
                  
                    <div id='header-desktop-dropdown'>
                        <button onClick={() => { navigate('/team') }} id='header-desktop-dropdown-open-btn' className='fs-header fc-black ffam-nunito fw-extra-bold'>
                            TEAM
                        </button>
                       
                    </div>
                    <div id='header-desktop-dropdown'>
                        <button onClick={() => { navigate('/roadmap') }} id='header-desktop-dropdown-open-btn' className='fs-header fc-black ffam-nunito fw-extra-bold'>
                            ROADMAP
                        </button>
                   
                    </div>
                   
                    <button onClick={() => { navigate('/mint') }} id='header-desktop-dropdown-open-btn' className='fs-header fc-black ffam-nunito fw-extra-bold'>
                        MINT
                    </button>
                </div>
                <div className='header-right'>
                    <button className='header-mint-btn ffam-quick fs-s fw-bold '>
                        <span className='text-3xl font-bold underline'>
                            Connect
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Header;
