
import './App.scss';
import Header from './components/header/Header'
import { Routes, Route } from "react-router-dom";
import Mint from './pages/mint/Mint'
import LandingPage from './pages/landing-page/LandingPage'
import WelcomePage from './pages/welcome/Welcome';
import Team from './pages/team/Team'
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Roadmap from './pages/roadmap/Roadmap'

function App() {
  const location = useLocation()
  const [useHeader, setUseHeader] = useState(null)
  useEffect(() => {
    if (location.pathname === '/' && window.innerWidth<501) {
      setUseHeader(false)
    }
    else {
      setUseHeader(true)
    }
  }, [location])
 
  return (
    <div className="app">
      <Header useHeader={useHeader} />
      <Routes>
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/team" element={<Team />} />
        <Route path="/roadmap" element={<Roadmap/>}/>
      </Routes>
    </div>
  );
}

export default App;
