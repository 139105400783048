import React from 'react'
import './Team.scss'
import img1 from '../../assets/images/team/1.webp'
import img2 from '../../assets/images/team/2.webp'
import img3 from '../../assets/images/team/3.webp'
import img4 from '../../assets/images/team/4.webp'
import img5 from '../../assets/images/team/5.webp'
import Footer from '../../components/footer/Footer'

function Story() {
    const revealTeam = (num) => {
        
    }
    return (
        <div className='team-page'>
            <div className='team-about-us'>
                <p className='team-about-title ffam-serifiyan fs-l fw-semi-bold'>
                    About Us
                </p>

                <p className='team-about-paragraph ffam-poppins fs-xs fw-med fc-l-gray'>
                    The HealthCare Champions and Essential Workers NFTs were created through a partnership of 3 healthcare workers: mother, son and daughter. Collaborated with a fantastic designer Latu Arifian Priyono.
                    <br />
                    <br />
                    The purpose of these NFTs are to document a point in time where a global health crisis was attacking us at a rapid rate.
                    <br />
                    <br />
                    Stepping up without any reservations we’re the HealthCare Champions and Essential Workers. Coming together to defeat the enemy, while saving as many lives as possible!
                </p>
            </div>
            <div className='team-wrapper '>

                <div onClick={() => { revealTeam(1) }} className='team-section'>
                    <img className='team-section-bg-img' src={img1} />
                    <div className='team-section-initial'>
                        <div className='team-section-top'>
                        </div>
                        <div className='team-section-bottom'>
                            <div className='team-section-name '>
                                <span className='ffam-poppins fs-xs fw-semi-bold'>CEO</span>
                                <span className='ffam-poppins fs-m fw-bold '>DAVID</span>
                            </div>
                            <p className='fs-xxs ffam-poppins'>
                            Experienced in healthcare, stocks, cryptocurrency, and entrepreneurship, with a background in mental health. Holds a degree in aviation science and certifications as a phlebotomist and real estate agent. Join us on an exhilarating journey with Healthcare Champions NFTs as we create an unstoppable community, mirroring the support witnessed during the Pandemic. We're not just launching NFTs and a website; we're paving a path to prosperity for all! Imagine a united community where we grow together, uplift one another, and work towards an electrifying interactive game with a thriving in-game economy.
                            </p>
                        </div>
                    </div>
                </div>

                <div onClick={() => { revealTeam(2) }} className='team-section'>
                    <img className='team-section-bg-img' src={img2} />
                    <div className='team-section-initial'>
                        <div className='team-section-top'>
                            {/* <img src={teamImg}/> */}
                        </div>
                        <div className='team-section-bottom'>
                            <div className='team-section-name '>
                                <span className='ffam-poppins fs-xs fw-semi-bold'>CFO</span>
                                <span className='ffam-poppins fs-m fw-bold'>GRACE</span>
                            </div>
                            <p className='fs-xxs ffam-poppins'>
                                A nurse with a Masters in Healthcare Administration and experience in healthcare finances and budgets. Passionate about educating new nursing students in the profession. The backbone of healthcare is to educate the next generation of Health Champions. Promoted wellness in underserved communities through teaching disease processes to meet healthcare goals and keep healthcare costs low and affordable.
                            </p>
                        </div>
                    </div>
                </div>
                <div onClick={() => { revealTeam(4) }} className='team-section'>
                    <img className='team-section-bg-img' src={img3} />
                    <div className='team-section-initial'>
                        <div className='team-section-top'>
                        </div>
                        <div className='team-section-bottom'>
                            <div className='team-section-name '>
                                <span className='ffam-poppins fs-xs fw-semi-bold'>AMBASSADOR</span>
                                <span className='ffam-poppins fs-m fw-bold'>VANESSA</span>
                            </div>
                            <p className='fs-xxs ffam-poppins'>
                                A Respiratory Therapist holding degrees in Natural Science, Mathematics, and Spanish. Working for the city for the last 20 years. A promoter of physical, social, and mental health wellness activities. Marketing experience with social media and traditional methods.
                            </p>
                        </div>
                    </div>
                </div>
                <div onClick={() => { revealTeam(4) }} className='team-section'>
                    <img className='team-section-bg-img' src={img4} />
                    <div className='team-section-initial'>
                        <div className='team-section-top'>
                        </div>
                        <div className='team-section-bottom'>
                            <div className='team-section-name '>
                                <span className='ffam-poppins fs-xs fw-semi-bold'>Art Designer</span>
                                <span className='ffam-poppins fs-m fw-bold'>LATU</span>
                            </div>
                            <p className='fs-xxs ffam-poppins'>
                                A 3D designer generalist, graduated from the Indonesian Institute of the Arts, has more than 7 years’ experience in the field of animation and experience working on NFTs of various projects. It's an honor to join the Healthcare Champions NFTs Project, because it's not just about a piece of digital art, but it's about humanity!
                            </p>
                        </div>
                    </div>
                </div>
                <div onClick={() => { revealTeam(4) }} className='team-section'>
                    <img className='team-section-bg-img' src={img5} />
                    <div className='team-section-initial'>
                        <div className='team-section-top'>
                        </div>
                        <div className='team-section-bottom'>
                            <div className='team-section-name '>
                                <span className='ffam-poppins fs-xs fw-semi-bold'>DEVELOPER</span>
                                <span className='ffam-poppins fs-m fw-bold'>BARAKA</span>
                            </div>
                            <p className='fs-xxs ffam-poppins'>
                                As a blockchain engineer with over 3 years of experience, I have a strong background in computer science and a deep understanding of blockchain technology and its potential applications.
                                <br />
                                I am committed to using my expertise to revolutionize industries and create opportunities for businesses and individuals.
                                
                               
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>

    )
}

export default Story